<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Enero 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
        <section class="d-flex">
            <!-- Filtrar por Norma -->
            <v-text-field class="me-2 filtro" v-model="buscarNorma" label="Norma" outlined dense hide-details></v-text-field>

            <!-- Este botón abre un diálogo para agregar una nueva norma -->
            <div v-if="userRoles.includes(roles.Configuraciones_Administrativo_Norma_Maestro)">
                <v-tooltip left color="success" :open-on-focus="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small fab color="success" v-bind="attrs" v-on="on"
                            @mousedown.prevent="dialogoNorma = true">
                            <v-icon> add </v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar</span>
                </v-tooltip>
            </div>
        </section>

        <!-- Diálogo para crear/editar una norma -->
        <v-dialog v-model="dialogoNorma" transition="dialog-bottom-transition" max-width="33rem" persistent :editar="false">
            <v-card>
                <v-card-title class="encabezadoDialogo">
                    <span class="text-h6"> {{ tituloDialogo }} </span>
                </v-card-title>
                <v-card-text class="pt-5">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <!-- Formulario para registrar una norma -->
                        <v-form lazy-validation>
                            <div class="d-flex">
                                <!-- Campo para ingresar la descripción -->
                                <validation-provider class="campo" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-text-field v-model="crearNorma.descripcionNorma" label="Descripción" dense outlined
                                    :error-messages="validarNorma || errors" readonly @mousedown.prevent="abrirDescripcion()">
                                    </v-text-field>
                                </validation-provider>
                                <!-- Campo para ingresar la fecha de vigencia -->
                                <v-dialog ref="menuFecha" v-model="menuFecha" transition="dialog-bottom-transition" max-width="18rem" persistent
                                    :close-on-content-click="false" :return-value.sync="crearNorma.fechaVigencia" :nudge-top="25" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <validation-provider class="ms-2 campo" v-slot="{ errors }" :rules="{ required: true}">
                                            <v-text-field v-model="crearNorma.fechaVigencia" label="Fecha de vigencia" v-bind="attrs" v-on="on"
                                            :error-messages="errors" outlined dense readonly>
                                            </v-text-field>
                                        </validation-provider>
                                    </template>
                                    <v-date-picker v-model="crearNorma.fechaVigencia" :first-day-of-week="1" locale="es-co" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="crearNorma.fechaVigencia = ''">Borrar</v-btn>
                                        <v-btn text color="primary" @click="$refs.menuFecha.save(crearNorma.fechaVigencia)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                                
                            </div>
                            
                            <!-- Botones del formulario -->
                            <div class="d-flex justify-end">
                                <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
                                <v-btn color="success" :disabled="invalid || botonValidacion" text depressed
                                @click="!editar ? agregarNorma() : editarNorma()">Guardar</v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- diálogo para la descripción -->
        <v-dialog v-model="dialogoDescripcion" transition="dialog-bottom-transition" max-width="25rem" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h6"> Descripción de la norma </span>
                </v-card-title>
                <v-card-text class="pt-5">
                    <validation-observer ref="formDescripcion" v-slot="{ invalid }">
                        <div class="d-flex">
                            <validation-provider class="campo" v-slot="{ errors }" :rules="{ descripcion: true }">
                                <v-text-field v-model="crearDescripcion.tipo" label="Tipo de norma" outlined dense
                                    :error-messages="errors" @keypress="validarLetras" @paste.prevent>
                                </v-text-field>
                            </validation-provider>

                            <div class="d-flex">
                                <validation-provider class="campo ms-2" v-slot="{ errors }" :rules="{ descripcion: true }">
                                    <v-text-field v-model="crearDescripcion.numero" label="Número" outlined dense 
                                        @keypress="validarNumeros" :error-messages="errors" @paste.prevent>
                                    </v-text-field>
                                </validation-provider>
                                
                                <validation-provider class="campo ms-2" v-slot="{ errors }" :rules="{ descripcion: true }">
                                    <v-text-field v-model="crearDescripcion.año" label="Año" outlined dense maxlength="4"
                                        @keypress="validarNumeros" :error-messages="errors" @paste.prevent>
                                    </v-text-field>
                                </validation-provider>
                            </div>
                        </div>
                        <v-alert color="grey lighten-2" style="line-height: 0.8rem;">
                            <span v-if="crearDescripcion.tipo || crearDescripcion.numero || crearDescripcion.año">
                                {{ crearDescripcion.tipo.toUpperCase() }} {{ crearDescripcion.numero }} 
                                <span v-if="crearDescripcion.tipo && crearDescripcion.numero && crearDescripcion.año"> DE </span> 
                                {{ crearDescripcion.año }}
                            </span>
                            <span v-else>&nbsp;</span>
                        </v-alert>
                        <div class="d-flex justify-end">
                            <v-btn class="me-2" text color="error" @click="clearDescripcion()">Cerrar</v-btn>
                            <v-btn color="success" :disabled="crearDescripcion.año.length !== 4 || invalid" text depressed @click="guardarDescripcion()">Guardar</v-btn>
                        </div>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla de las normas -->
        <v-data-table class="elevation mt-4" :items="tablaNorma.items" :loading="tablaNorma.loading"
            fixed-header :headers="tablaNorma.headers" :footer-props="tablaNorma.footerProps"
            :items-per-page.sync="tablaNorma.itemsPerPage" :page.sync="tablaNorma.page"
            :server-items-length="tablaNorma.totalPage" height="64vh"> 
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.idNorma">
                        <!-- <td> {{ item.idNorma }}</td> -->
                        <td> {{ item.descripcionNorma }} </td>
                        <td class="text-center"> {{ item.fechaVigencia }} </td>
                        <!-- Columna del estado -->
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'">
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <!-- Columna de la trazabilidad -->
                        <td class="text-center">
                            <!-- Botón que permite ver la trazabilidad que ha tenido una norma -->
                            <v-tooltip bottom color="blue">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="verTrazabilidadNorma(item)">
                                        <v-icon color="blue"> backup_table </v-icon>
                                    </v-btn>
                                </template>
                                <span>Trazabilidad</span>
                            </v-tooltip>
                        </td>
                        <!-- Columna de las acciones -->
                        <td class="text-center" v-if="userRoles.includes(roles.Configuraciones_Administrativo_Norma_Maestro)">
                            <!-- Botón que permite editar una norma -->
                            <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'gray'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="item.eliminado === false && abrirDialogoEditar(item)">
                                        <v-icon :color="item.eliminado === false ? 'orange' : 'gray'">
                                            border_color
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ item.eliminado === false ? 'Editar' : 'Norma inactiva' }}</span>
                            </v-tooltip>

                            <!-- Botón que permite cambiar el estado de una norma -->
                            <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'gray'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="item.eliminado === false && abrirDialogoEstado(item)">
                                        <v-icon :color="item.eliminado === false ? 'error' : 'gray'">
                                            {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ item.eliminado === false ? 'Inactivar' : 'Norma inactiva' }}</span>
                            </v-tooltip>
                        </td>
                        <!-- Columna de los productos -->
                        <td class="text-center">
                            <!-- Botón que permite agregar productos a norma -->
                            <v-tooltip v-if="userRoles.includes(roles.Configuraciones_Administrativo_Norma_Maestro)" bottom :color="item.eliminado === false ? 'success' : 'gray'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="item.eliminado === false && abrirDialogoCrearNormaProducto(item)">
                                        <v-icon :color="item.eliminado === false ? 'success' : 'gray'">
                                            playlist_add
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ item.eliminado === false ? 'Agregar productos a la norma' : 'Norma inactiva' }}</span>
                            </v-tooltip>
                            <!-- Botón que permite ver los productos de una norma -->
                            <v-tooltip bottom color="blue">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="abrirDialogoVerNormaProducto(item)">
                                        <v-icon color="blue"> visibility </v-icon>
                                    </v-btn>
                                </template>
                                <span>Ver norma producto</span>
                            </v-tooltip>
                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="descargarCsv(item)">
                                        <v-icon color="primary"> download </v-icon>
                                    </v-btn>
                                </template>
                                <span>Descargar CSV</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- dialogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6">¿Desea inactivar la norma?</span>
                </v-card-title>
                <v-card-text class="pt-5">
                    <div>
                        <p v-if="cargando" class="text-center mt-5">
                        Por favor espere un momento...
                        <v-icon :class="{ 'rotate-animation': cargando }"
                            large>rotate_right
                        </v-icon>
                    </p>
                    </div>
                    
                    <div class="d-flex justify-end">
                        <v-btn v-if="!cargando" class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn v-if="!cargando" color="success" depressed text @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- dialogo para ver trazabilidad de una norma -->
        <v-dialog v-model="dialogoTrazabilidadNorma" persistent transition="dialog-bottom-transition" max-width="60rem">
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6"> Trazabilidad:
                        {{ normaSeleccionada.descripcionNorma }}
                    </span>
                </v-card-title>
                <v-card-text class="pt-5">
                    <v-data-table class="elevation trazabilidad" :items="tablaTrazabilidadNorma.items"
                        :headers="tablaTrazabilidadNorma.headers" :footer-props="tablaTrazabilidadNorma.footerProps"
                        :items-per-page="tablaTrazabilidadNorma.itemsPerPage" :page.sync="tablaTrazabilidadNorma.page"
                        :sort-by="'time'" :sort-desc="true" height="45vh" fixed-header>
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="item in items" v-bind:key="item.rev">
                                    <td> {{ nombresCampos[item.campo.toLowerCase()] }} </td>
                                    <td>
                                        <template v-if="item.campo === 'eliminado'">
                                            <span v-if="item.anterior === 'false'">Activo</span>
                                            <span v-if="item.anterior === 'true'">Inactivo</span>
                                        </template>
                                        <template v-if="validarCampos(item)">
                                            {{ item.anterior }}
                                        </template>
                                    </td>
                                    <td>
                                        <template v-if="item.campo === 'eliminado'">
                                            <span v-if="item.nuevo == 'false'">Activo</span>
                                            <span v-if="item.nuevo == 'true'">Inactivo</span>
                                        </template>
                                        <template v-if="validarCampos(item)">
                                            {{ item.nuevo }}
                                        </template>
                                    </td>
                                    <td class="text-center">
                                        {{ formatoFecha(item.time) }}
                                    </td>
                                    <td class="text-center">
                                        {{ item.username }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                        <template v-slot:footer.page-text="items">
                            {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
                        </template>
                    </v-data-table>
                    <div class="d-flex justify-end mt-2">
                        <v-btn color="error" text @click="dialogoTrazabilidadNorma = false">Cerrar</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- dialogo crear una relación norma producto -->
        <v-dialog v-model="dialogoCrearNormaProducto" transition="dialog-bottom-transition" width="auto" persistent>
            <NormaProducto 
                :buscar="buscar" 
                :crear="crear"
                :validarNumeros="validarNumeros"
                :normaSeleccionada="normaSeleccionada"
                :almacenar="almacenar"
                :clearCrearNormaProducto="clearCrearNormaProducto"
                :tablaNormaProducto="tablaNormaProducto"
                :listarNorma="listarNorma"/>
        </v-dialog>

        <!-- dialogo ver los productos de la norma -->
        <v-dialog v-model="dialogoVerNormaProducto" transition="dialog-bottom-transition" max-width="75rem" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6">PRODUCTOS DE LA {{ normaSeleccionada.descripcionNorma }}</span>
                </v-card-title>
                <v-card-text class="pt-5">
                    <NormaProducto  ref="verNormaProducto"
                        :buscar="buscar"
                        :tablaNormaProducto="tablaNormaProducto"
                        :validarNumeros="validarNumeros"
                        :normaSeleccionada="normaSeleccionada"
                        :listarNormaProducto="listarNormaProducto"
                        :formatoFecha="formatoFecha"
                        :dialogoVerNormaProducto="dialogoVerNormaProducto"/>
                    <div class="d-flex justify-end">
                        <v-btn class="me-2" color="error" text @click="clearVerNormaProducto()">CERRAR</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import Papa from 'papaparse';
import { mapMutations, mapState } from "vuex";
import { Role } from "../../../../router/role.js";
import { required } from 'vee-validate/dist/rules';
import NormaProducto from "../../../../components/NormaProducto.vue";
import { extend, ValidationObserver, ValidationProvider} from 'vee-validate';

extend('required', {
    ...required,
    message: 'Este campo es requerido.',
});

extend('descripcion', {
    ...required,
    message: 'Obligatorio.',
});

/**
 * Esta función toma una matriz de matrices (una matriz bidimensional) 
 * donde cada sub-matriz (o fila) representa una fila de datos en el CSV.
 * Cada elemento dentro de las sub-matrices se une con un delimitador 
 * (en este caso un punto y coma ';') y cada fila se une con un salto de línea.
 * 
 * @param {Array} data - Matriz bidimensional que contiene los datos.
 * @returns {string} - Cadena representando los datos en formato CSV.
 */
 function arrayToCSV(data) {
    return data.map(row => row.join(";")).join("\n");
}

/**
 * Esta función crea un archivo en formato CSV a partir de una cadena 
 * y luego inicia una descarga en el navegador del usuario para ese archivo.
 * 
 * @param {string} csvData - Cadena que contiene los datos en formato CSV.
 * @param {string} filename - Nombre que se asignará al archivo descargado.
 */
 function downloadCSV(csvData, filename) {
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export default {
    name: "Norma",
    props: {
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        NormaProducto,
    },
    data() {
        return {
            buscarNorma: '',
            dialogoNorma: false,
            dialogoDescripcion: false,
            menuFecha: false,
            crearNorma: {
                descripcionNorma: '',
                fechaVigencia: '',
            },
            crearDescripcion: {
                tipo: '',
                numero: '', 
                año: ''
            },
            botonValidacion: false,
            validarNorma: '',
            tituloDialogo: 'Agregar norma',
            editar: false,
            tablaNorma: {
                items: [],
                loading: false,
                headers: [
                    { text: "NORMA", width: "29%", sortable: false },
                    { text: "FECHA VIGENCIA", align: "center", width: "13%", sortable: false },
                    { text: "ESTADO", width: "13%", sortable: false },
                    { text: "TRAZABILIDAD", align: "center", width: "14%", sortable: false },
                    { text: "PRODUCTOS", align: "center", width: "16%", sortable: false }
                ],
                footerProps: {
                    'items-per-page-options': [10, 20, 30, 40],
                    'items-per-page-text': 'Items por página:',
                    'show-current-page': true,
                    'show-first-last-page': true
                },
                itemsPerPage: 10,
                page: 1,
                totalPage: 0
            },
            normaSeleccionada: {},
            dialogoCambiarEstado: false,
            cargando: false,
            dialogoTrazabilidadNorma: false,
            tablaTrazabilidadNorma: {
                items: [],
                headers: [
                    { text: "CAMPO", align: "left", width: "20%", sortable: false },
                    { text: "VALOR ANTERIOR", align: "left", width: "20%", sortable: false },
                    { text: "VALOR ACTUAL", align: "left", width: "20%", sortable: false },
                    { text: "FECHA DE MODIFICACIÓN", align: "center", width: "25%", sortable: false },
                    { text: "USUARIO", align: "center", width: "15%", sortable: false },
                ],
                footerProps: {
                    'show-current-page': true,
                    'show-first-last-page': true,
                },
                itemsPerPage: 5,
                page: 1,
            },
            nombresCampos: {
                descripcion_norma: 'Nombre',
                eliminado: 'Estado',
                fecha_vigencia: 'Fecha de vigencia'
            },
            crear: false,
            dialogoCrearNormaProducto: false,
            almacenar: {
                fechaVigencia: '',
                descripcionNorma: ''
            },
            dialogoVerNormaProducto: false,
            tablaNormaProducto: {
                items: [],
                loading: false,
                headers: [
                    { text: "CÓDIGO", width: "5rem", sortable: false },
                    { text: "PRODUCTO", width: "25rem", sortable: false },
                    { text: "PRECIO MÁXIMO REGULADO",align: "center", width: "10rem", sortable: false },
                    { text: "ESTADO", align: "center", width: "7rem", sortable: false },
                    { text: "TRAZABILIDAD", align: "center", width: "7rem", sortable: false }
                ],
                footerProps: {
                    'items-per-page-options': [10, 15, 20, 30],
                    'items-per-page-text': 'Items por página:',
                    'show-current-page': true,
                    'show-first-last-page': true,
                },
                itemsPerPage: 10,
                page: 1,
                totalPage: 0
            },
            buscar: {
                codigo: '',
                descripcion: ''
            },
            userRoles: {},
            roles: Role,
        }
    },
    created() {
        this.userRoles = this.auth.roles;
        if (this.userRoles.includes(this.roles.Configuraciones_Administrativo_Norma_Maestro)) {
            this.tablaNorma.headers.splice(4,0,{ text: "ACCIONES", align: "center", width: "14%", sortable: false });
        }
        if (this.userRoles.includes(this.roles.Configuraciones_Administrativo_Norma_Maestro)) {
            this.tablaNormaProducto.headers.splice(4,0,{ text: "ACCIONES", align: "center", width: "7rem", sortable: false });
        }
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
    },
    watch: {
        'tablaNormaProducto.itemsPerPage': function () {
            this.tablaNormaProducto.page = 1;
            this.listarNormaProducto();
        },
        'tablaNormaProducto.page': function () {
            this.listarNormaProducto();
        },
        'buscarNorma': function () {
            this.tablaNorma.page = 1;
            this.listarNorma();
        },
        'tablaNorma.itemsPerPage': function () {
            this.tablaNorma.page = 1;
            this.listarNorma();
        },
        'tablaNorma.page': function () {
            this.listarNorma();
            this.goTo();
        },
        'tablaTrazabilidadNorma.items': function () {
            this.tablaTrazabilidadNorma.page = 1;
        },
        'buscar.codigo' : function () {
            this.listarNormaProducto();
            this.tablaNormaProducto.page = 1;
        },
        'buscar.descripcion' : function () {
            this.listarNormaProducto();
            this.tablaNormaProducto.page = 1;
        }
    },
    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),
        /**
         * Método para restablecer el scroll
         */
        goTo() {
            this.$vuetify.goTo(
                '.elevation',{
                    offset: 100,
                    container: '.v-data-table__wrapper'
                }
            )
        },
        /**
         * Método que lista las normas que cumplen con ciertos parámetros de búsqueda.
         */
        listarNorma() {
            this.tablaNorma.loading = true;
            this.$http.get(`msa-administration/norma/listarNormas`, {
                params: {
                    page: this.tablaNorma.page -1,
                    size: this.tablaNorma.itemsPerPage,
                    descripcionNorma: `${this.buscarNorma.toLowerCase()}`
                }
            }).then((response) => {
                this.tablaNorma.items = response.data.content;
                this.tablaNorma.totalPage = response.data.totalElements;
                this.tablaNorma.loading = false;
            }).catch((error) => {
                console.log(error);
                    
            })
        },
        /**
         * Método que permite crear una norma en base de datos.
         */
        agregarNorma() {
            const norma = {
                descripcionNorma: this.crearNorma.descripcionNorma,
                fechaVigencia: `${this.crearNorma.fechaVigencia}T00:00:00-05:00`,
                empresa: {
                    idEmpresa: this.enterprise.code
                },
                lastmodifiedby: this.lastmodifiedby
            }
            this.$http
                .post(`msa-administration/norma/guardar`, norma)
                .then(() => {
                    this.listarNorma();
                    this.dialogoNorma = false;
                    this.clear();
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Abre el diálogo que permite editar la norma seleccionada.
         */
        abrirDialogoEditar(item) {
            this.editar = true;
            this.dialogoNorma = true;
            this.crearNorma = { ...item };
            this.normaSeleccionada = item;
            this.tituloDialogo = 'Editar norma';
        },
        /**
         * Edita la información de la norma.
         */
        editarNorma() {
            const norma = {
                idNorma: this.normaSeleccionada.idNorma,
                descripcionNorma: this.crearNorma.descripcionNorma,
                fechaVigencia: `${this.crearNorma.fechaVigencia}T00:00:00-05:00`,
                empresa: {
                    idEmpresa: this.enterprise.code
                },
                lastmodifiedby: this.lastmodifiedby
            }
            this.$http
                .put(`msa-administration/norma/actualizar`, norma)
                .then(() => {
                    this.listarNorma();
                    this.dialogoNorma = false;
                    this.clear();
                })
        },
        /**
         * Abre el diálogo para inactivar una norma en específico.
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true;
            this.normaSeleccionada = item;
        },
        /**
         * Inactiva la norma seleccionada
         */
        cambiarEstado() {
            this.cargando = true;
            const cambiarEstado = {
                idNorma: this.normaSeleccionada.idNorma,
                eliminado: true,
                lastmodifiedby: this.lastmodifiedby
            }
            this.$http
                .put(`msa-administration/norma/actualizarEstado`, cambiarEstado)
                .then(() => {
                    this.listarNorma();
                    this.dialogoCambiarEstado = false;
                    this.cargando = false;
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Abre el diálogo para parametrizar productos a la norma.
         */
        abrirDialogoCrearNormaProducto(item) {
            this.dialogoCrearNormaProducto = true;
            this.normaSeleccionada = item;
            this.almacenar = {...item};
            this.crear = true;
        },
        /**
         * Abre el dialogo con los producto parametrizados a la norma.
         */
        abrirDialogoVerNormaProducto(item) {
            this.dialogoVerNormaProducto = true;
            this.normaSeleccionada = item;
            this.listarNormaProducto();
            this.tablaNormaProducto.page = 1;
        },
        /**
         * Listas las normas en la tabla que cumplen con ciertos criterios de búsqueda
         */
        listarNormaProducto() {
            this.tablaNormaProducto.loading = true;
            this.$http.get(`msa-administration/detalleNorma/listarDetalleNorma`, {
                params: {
                    page: this.tablaNormaProducto.page -1,
                    size: this.tablaNormaProducto.itemsPerPage,
                    idNorma: this.normaSeleccionada.idNorma,
                    codigo: `${this.buscar.codigo}`,
                    descripcion: `${this.buscar.descripcion.toLowerCase()}`
                }
            }).then((response) => {
                this.tablaNormaProducto.loading = false;
                this.tablaNormaProducto.items = response.data.content;
                this.tablaNormaProducto.totalPage = response.data.totalElements;
            }).catch((error) => {
                console.log(error);
                this.tablaNormaProducto.loading = false;
            })
        },
        /**
         * Este método permite descargar en formato CSV los productos parametrizados a una norma en específico.
         */
        descargarCsv(item) {
            this.normaSeleccionada = item;
            this.$http
            .get(`msa-administration/detalleNorma/exportarCsv`, {
                responseType: 'blob',
                params: {
                    idNorma: this.normaSeleccionada.idNorma
                }
            }).then(response => {
                const filename = `PRODUCTOS DE LA ${this.normaSeleccionada.descripcionNorma}.csv`;
                Papa.parse(response.data, {
                    complete: (results) => {
                        const csvData = arrayToCSV(results.data);
                        downloadCSV(csvData, filename);
                    }
                });
            }).catch(error => {
                console.log(error);
            })
        },
        /**
         * Esta función se utiliza para validar que solo se ingresen letras(con y sin tíldes), espacios, puntos y guiones en un campo de entrada de texto
         * si la tecla presionada no corresponde a la condición, se evita que se ingrese el caracter
         * @param {evento} event - El evento del teclado que se produce al escribir en el campo de entrada
         */
         validarLetras(event) {
            const key = event.key;
            if (!key.match(/[a-zA-ZáéíóúÁÉÍÓÚ.-]/)) {
                event.preventDefault();
            }
        },
        /**
         * Esta función se utiliza para validar que solo se ingresen números, puntos y guiones en un campo de entrada de texto
         * si la tecla presionada no corresponde a la condición, se evita que se ingrese el caracter
         * @param {event} event - El evento del teclado que se produce al escribir en el campo de entrada
         */
        validarNumeros(event) {
            const key = event.key;
            if (!((key >= '0' && key <= '9'))) {
                event.preventDefault();
            }
        },
        /**
         * Abre el diálogo de la descripción y si es edición los separa en partes y cada parte
         * se almacena en su campo correspondiente.
         */
        abrirDescripcion() {
            if (this.crearNorma.descripcionNorma != '') {
                const partes = this.crearNorma.descripcionNorma.split(' ');
                this.crearDescripcion.tipo = partes[0] || '';
                this.crearDescripcion.numero = partes[1] || '';
                this.crearDescripcion.año = partes[3] || '';
                this.dialogoDescripcion = true;
            }
            this.dialogoDescripcion = true
        },
        /**
         * Almacena el nombre de la norma en otra variable para su creación o edición.
         */
        guardarDescripcion() {
            this.crearNorma.descripcionNorma = `${this.crearDescripcion.tipo.toUpperCase()} ${this.crearDescripcion.numero} DE ${this.crearDescripcion.año}`;
            this.validarExistencia();
            this.clearDescripcion();
        },
        /**
         * Busca si existe una norma con el mismo nombre que se está digitando en el campo de entrada, 
         * si existe no deja crear la norma, si no, se habilita el botón de guardar.
         */
        validarExistencia() {
            this.$http.get(`msa-administration/norma/validarExistencia`, {
                params: {
                    descripcionNorma: this.crearNorma.descripcionNorma
                }
            }).then((response) => {
                if (response.data != null) {
                    this.validarNorma = "La norma ya existe.";
                    this.botonValidacion = true;
                } else {
                    this.validarNorma = "";
                    this.botonValidacion = false;
                }
            }).catch((error) => {
                console.log(error);
            })
            
        },
        /**
         * Muestra tabla de trazabilidad de una norma determinada.
         * @param {Object} item - El objeto de la norma seleccionada para el cual se mostrará 
         * la trazabilidad.
         */
        verTrazabilidadNorma(item) {
            this.dialogoTrazabilidadNorma = true;
            this.normaSeleccionada = item;
            this.$http.get(`msa-administration/norma/trazabilidad`, {
                params: {
                    id: this.normaSeleccionada.idNorma
                }
            }).then((response) => {
                this.tablaTrazabilidadNorma.items = response.data;
            }).catch((error) => {
                console.log(error);
            })
        },
        /**
         * Valida si el campo de un objeto debe ser excluido en la tabla de trazabilidad
         * @param {Object} item - El objeto que contiene el campo a validar.
         * @param {string} item.campo - El nombre del campo a validar.
         */
        validarCampos(item) {
            const excluir = ['descripcion_norma', 'fecha_vigencia']
            return excluir.includes(item.campo)
        },
        /**
         * Formatea una cadena de fecha y hora en un formato "YYYY/MM/DD HH:MM a.m./p.m."
         * @param {string} dateTimeString - La cadena de fecha y hora en formato ISO 8601.
         */
        formatoFecha(dateTimeString) {
            const fecha = new Date(dateTimeString);
            const horas = fecha.getHours();
            const minutos = fecha.getMinutes();
            const ampm = horas >= 12 ? 'p.m.' : 'a.m.';
            const formatoHoras = horas % 12 || 12;
            const formatoMinutos = minutos < 10 ? `0${minutos}` : minutos;
            const formatoMes = (fecha.getMonth() + 1).toString().padStart(2, '0');
            const formatoDia = fecha.getDate().toString().padStart(2, '0');
            const formatoFechaHora = `${fecha.getFullYear()}/${formatoMes}/${formatoDia} ${formatoHoras.toString().padStart(2, '0')}:${formatoMinutos} ${ampm}`;
            return formatoFechaHora;
        },
        /**
         * Reinicia las variables que conforman la descripción de la norma.
         */
        clearDescripcion() {
            this.$refs.formDescripcion.reset();
            this.dialogoDescripcion = false;
            this.crearDescripcion.tipo = '';
            this.crearDescripcion.numero = '';
            this.crearDescripcion.año = '';
        },
        /**
         * Cierra el diálogo que parametriza los productos.
         */
        clearCrearNormaProducto() {
            this.dialogoCrearNormaProducto = false;
            setTimeout(() => {
                this.crear = false;
            }, 500);
        },
        /**
         * Reinicia las variables que se usan en el apartado de ver produtos de la norma.
         */
        clearVerNormaProducto() {
            this.dialogoVerNormaProducto = false;
            this.buscar.codigo = '';
            this.buscar.descripcion = '';
            this.crear = false;
            this.$refs.verNormaProducto.edicion = false;
            this.$refs.verNormaProducto.snackbar = false;
            this.listarNormaProducto();
            this.tablaNormaProducto.footerProps = {
                'disablePagination' : false, 
                'disableItemsPerPage' : false ,
                'items-per-page-options': [10, 15, 20, 30],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            }
        },
        /**
         * Reinicia las variables de la norma.
         */
        clear() {
            this.$refs.observer.reset();
            this.dialogoNorma = false;
            setTimeout(() => {
                this.tituloDialogo = 'Agregar norma';
                this.editar = false;
                this.crearNorma.descripcionNorma = '';
                this.crearNorma.fechaVigencia = '';
            }, 500);
            this.botonValidacion = false;
            this.validarNorma = '';
        }
    },
    mounted() {
        this.listarNorma();
        this.lastmodifiedby = this.auth.username.toUpperCase();
    }
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .v-dialog--active {
    overflow-y: hidden !important;
}
.encabezadoDialogo{
    background-color: #1867c0;
    color: white;
}
.contenido {
    padding: 1rem;
    width: 100%;
    height: 85vh;
}
.campo {
    width: 100%;
}
::v-deep .elevation .v-data-footer {
    width: 100%;
}
::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
.encabezado {
    background-color: #1867c0;
    color: white;
}
::v-deep .trazabilidad .v-data-footer__select {
    display: none;
}
::v-deep .trazabilidad .v-data-footer {
    padding-top: 10px;
    justify-content: end;
}
.rotate-animation {
    animation: rotate 2s linear infinite;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>