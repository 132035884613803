<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estafania Villada Chavarria                                   ###### -->
<!-- ###### @date: Enero 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div>
        <!-- Contenido para ver los productos de una norma en específico -->
        <div v-if="crear != true">
            <!-- Filtros de búsqueda de los productos de la norma -->
            <div class="d-flex align-center">
                <v-text-field class="me-2" v-model="buscar.codigo" label="Código" outlined dense hide-details @keypress="validarNumeros" :disabled="edicion"></v-text-field>
                <v-text-field v-model="buscar.descripcion" label="Producto" outlined dense hide-details :disabled="edicion"></v-text-field>
            </div>

            <!-- Tabla de los productos parametrizados-->
            <v-data-table class="elevation mt-4" :items="tablaNormaProducto.items" :loading="tablaNormaProducto.loading"
                fixed-header :headers="tablaNormaProducto.headers" :footer-props="tablaNormaProducto.footerProps"
                :items-per-page.sync="tablaNormaProducto.itemsPerPage" :page.sync="tablaNormaProducto.page"
                :server-items-length="tablaNormaProducto.totalPage" height="54vh">
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="item in items" v-bind:key="item.idNorma">
                            <td> {{ item.codigoProducto }} </td>
                            <td> {{ item.descripcionProducto }} </td>
                            <td class="text-end">
                                <span v-if="!item.editar"> $ {{ formatearPrecio(item.precioMaximo) }}</span>
                                <!-- Campo para editar el precio máximo -->
                                <div v-else class="d-flex">
                                    <v-text-field class="text-end" v-model="editarPrecio" outlined dense hide-details prefix="$"
                                        @keypress="validarNumeros" @paste.prevent @input="formatearPrecioCampoEdicion">
                                    </v-text-field>
                                </div>
                            </td>
                            <!-- Campo estado -->
                            <td>
                                <v-icon :color="item.estado === false ? 'success' : 'error'">
                                    {{ item.estado === false ? 'check_circle' : 'cancel' }}
                                </v-icon>
                                {{ item.estado === false ? 'Activo' : 'Inactivo' }}
                            </td>
                            <td class="text-center" v-if="userRoles.includes(roles.Configuraciones_Administrativo_Norma_Maestro)">
                                <!-- Botón que permite editar el precio máximo de una parametrización -->
                                <v-tooltip v-if="!item.editar" bottom :color="!item.estado && !edicion ? 'orange' : 'gray'">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @mousedown.prevent="!item.estado && !edicion && verCampoEditar(item)">
                                            <v-icon :color="!item.estado && !edicion ? 'orange' : 'gray'"> border_color </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ !item.estado ? 'Editar' : 'Producto de la norma inactivo' }}</span>
                                </v-tooltip>

                                <!-- Botón que permite cancelar la edicion del precio máximo -->
                                <v-tooltip v-else bottom color="error">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on">
                                            <v-icon color="error" @click="ocultarCampoEditar(item)"> disabled_by_default </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Cancelar</span>
                                </v-tooltip>

                                <!-- Botón que permite guardar la edicion del precio máximo -->
                                <v-tooltip v-if="item.editar" bottom :color="!snackbar ? 'success' : 'gray'">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click="snackbar || actualizarPrecio(item)">
                                            <v-icon color="success" :disabled="snackbar"> check_box </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Actualizar</span>
                                </v-tooltip>

                                <!-- Botón que permite eliminar una parametrización-->
                                <v-tooltip v-if="!item.editar" bottom :color="!item.estado && !edicion ? 'error' : 'gray'">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click="!item.estado && !edicion && abrirDialogoEliminar(item)">
                                            <v-icon :color="!item.estado && !edicion ? 'error' : 'gray'"> delete_forever </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ !item.estado ? 'Eliminar' : 'Producto de la norma inactivo' }}</span>
                                </v-tooltip>
                            </td>
                            <td class="text-center">
                                <!-- Botón que permite ver la trazabilidad que ha tenido una parametrizacion -->
                                <v-tooltip bottom :color="!item.editar && !edicion ? 'blue' : 'gray'">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click="!item.editar && !edicion && verTrazabilidad(item)" >
                                            <v-icon :color="!item.editar && !edicion ? 'blue' : 'gray'"> backup_table </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Trazabilidad</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-slot:footer.page-text="items">
                    {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
                </template>
            </v-data-table>

             <!-- v-snackbar para mostrar mensaje de validación del precio máximo -->
            <v-snackbar v-model="snackbar" :timeout="-1" color="red" top>
              <span class="font-weight-medium" style="color: white;">{{ mensajeValidacion }}</span>
            </v-snackbar>

            <!-- Dialogo de eliminar producto de la norma -->
            <v-dialog v-model="dialogoEliminar" transition="dialog-bottom-transition" max-width="25rem" persistent>
                <v-card>
                    <v-card-title class="encabezado">
                        <span class="text-h6"> ¿Eliminar producto de la norma? </span>
                    </v-card-title>
                    <v-card-text class="pt-5">
                        <div class="d-flex justify-end">
                            <v-btn class="me-2" color="error" text @click="dialogoEliminar = false">No</v-btn>
                            <v-btn color="success" depressed text @click="eliminarProductoNorma()">Si</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- Dialogo para ver la trazabilidad de un producto en la norma -->
            <v-dialog v-model="dialogoTrazabilidad" transition="dialog-bottom-transition" max-width="60rem" persistent>
                <v-card>
                    <v-card-title class="encabezado">
                        <span class="text-h6"> Trazabilidad: Código {{ normaProductoSeleccionado.codigoProducto }} </span>
                    </v-card-title>
                    <v-card-text class="pt-5">
                        <!-- Tabla de la trazabilidad -->
                        <v-data-table class="elevation trazabilidad" :items="tablaTrazabilidad.items"
                            :headers="tablaTrazabilidad.headers" :footer-props="tablaTrazabilidad.footerProps"
                            :items-per-page="tablaTrazabilidad.itemsPerPage" :page.sync="tablaTrazabilidad.page"
                            :sort-by="'time'" :sort-desc="true" height="20rem" fixed-header>
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr v-for="item in items" v-bind:key="item.rev">
                                        <td> {{ nombresCampos[item.campo.toLowerCase()] }} </td>
                                        <td>
                                            <template v-if="item.campo === 'estado'">
                                                <span v-if="item.anterior === 'false'">Activo</span>
                                                <span v-if="item.anterior === 'true'">Inactivo</span>
                                            </template>
                                            <template v-else-if="item.campo === 'precio_maximo'">
                                                <span v-if="item.anterior != ''"> $ {{ formatearPrecio(parseFloat(item.anterior)) }} </span>
                                            </template>
                                            <template v-else>
                                                {{ validarCampos(item) ? item.anterior : '' }}
                                            </template>
                                        </td>
                                        <td>
                                            <template v-if="item.campo === 'estado'">
                                                <span v-if="item.nuevo == 'false'">Activo</span>
                                                <span v-if="item.nuevo == 'true'">Inactivo</span>
                                            </template>
                                            <template v-else-if="item.campo === 'precio_maximo'">
                                               <span> $ {{ formatearPrecio(parseFloat(item.nuevo)) }} </span> 
                                            </template>
                                            <template v-else>
                                                {{ validarCampos(item) ? item.nuevo : '' }}
                                            </template>
                                        </td>
                                        <td class="text-center">
                                            {{ formatoFecha(item.time) }}
                                        </td>
                                        <td class="text-center">
                                            {{ item.username }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                            <template v-slot:footer.page-text="items">
                                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
                            </template>
                        </v-data-table>

                        <!-- Botones -->
                        <div class="d-flex justify-end">
                            <v-btn class="me-2" color="error" text @click="dialogoTrazabilidad = false">CERRAR</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- Contenido para parametrizar los productos con la norma -->
        <div v-if="crear === true">
            <v-card>
                <v-tabs v-model="tipoCreacion" background-color="blue darken-4" dark class="v-tabs--fixed-tabs menu">
                    <v-tabs-slider color="yellow" class="slider"></v-tabs-slider>
                    <v-tab> Agregar producto a la norma uno a uno </v-tab>
                    <v-tab> Carga masiva de productos a la norma </v-tab>
                </v-tabs>
            </v-card>

            <v-tabs-items v-model="tipoCreacion">
                <!-- Agregar producto a la norma uno a uno -->
                <v-tab-item>
                    <v-card-text>
                        <validation-observer ref="formNormaProducto" v-slot="{ invalid }">
                            <v-form>
                                <!-- Campo que almacena la información de la norma seleccionada -->
                                <div class="d-flex align-center" >
                                    <div class="norma">
                                        <span class="me-7"><strong>NORMA: </strong>{{ almacenar.descripcionNorma }}</span> 
                                        <span class="me-7"> <strong>FECHA DE VIGENCIA: </strong>{{ almacenar.fechaVigencia }}</span>
                                    </div>
                                </div>

                                <!-- Campo que almacena el tipo de código -->
                                <div class="d-flex mt-2">
                                    <v-checkbox class="ma-0 checkbox" v-model="opcionCodigo" label="CUM" value="cum" :rules="[v => v !== null || 'Seleccione una opción']"></v-checkbox>
                                    <v-checkbox class="ma-0" v-model="opcionCodigo" label="IUM" value="ium" hide-details :rules="[v => v !== null]"></v-checkbox>
                                </div>

                                <div class="d-flex mt-2">
                                    <!-- Campo que almacena el código a relacionar -->
                                    <validation-provider class="fila" v-slot="{ errors }" :rules="{ required: true }">
                                        <v-text-field v-model="crearNormaProducto.codigo" :label="(opcionCodigo ==='cum' ? 'CUM' : '') || (opcionCodigo === null ? '' : '') || (opcionCodigo ==='ium' ? 'IUM' : '')" dense outlined
                                            :error-messages="validarCodigo || errors" :disabled="opcionCodigo === null">
                                        </v-text-field>
                                    </validation-provider>

                                    <!-- Campo que almacena el precio máximo -->
                                    <validation-provider class="ms-2 fila" v-slot="{ errors }" :rules="{ required: true }">
                                        <v-text-field v-model="crearNormaProducto.precioMaximo" label="Precio máximo regulado" dense outlined prefix="$"
                                            :error-messages="errors" @keypress="validarNumeros" @input="formatoPrecio($event, 'precioMaximo')">
                                        </v-text-field>
                                    </validation-provider>
                                </div>

                                <!-- Campo que almacena la descripcion del producto -->
                                <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                                    <v-textarea v-model="crearNormaProducto.descripcion" label="Producto" dense outlined readonly
                                        row-height="4" auto-grow :error-messages="errors">
                                    </v-textarea>
                                </validation-provider>

                                <!-- Validación general -->
                                <div style="max-width: 676px;"><p class="color-red text-caption" >{{ errorGeneral }}</p></div>

                                <!-- Botones -->
                                <div class="d-flex justify-end">
                                    <v-btn color="error" text @click="clear()">CERRAR</v-btn>
                                    <v-btn depressed :disabled="invalid || botonDisabled" color="success" text @click="guardarNormaProducto()">GUARDAR</v-btn>
                                </div>

                            </v-form>
                        </validation-observer>
                    </v-card-text>
                </v-tab-item>

                <!-- Agregar relaciones masivas -->
                <v-tab-item>
                    <v-card-text>
                        <validation-observer>
                            <v-form>
                                <!-- Campo que almacena la información de la norma seleccionada -->
                                <div class="d-flex align-center" >
                                    <div class="norma">
                                        <span class="me-7"><strong>NORMA: </strong>{{ almacenar.descripcionNorma }}</span> 
                                        <span class="me-7"> <strong>FECHA DE VIGENCIA: </strong>{{ almacenar.fechaVigencia }}</span>
                                    </div>
                                </div>

                                <!-- Campo que almacena el tipo de código -->
                                <div class="d-flex mt-2">
                                    <v-checkbox class="ma-0 checkbox" v-model="opcionCodigo" label="CUM" value="cum" v-show="!verBotones" :rules="[v => v !== null || 'Selecciona una opción']"></v-checkbox>
                                    <v-checkbox class="ma-0" v-model="opcionCodigo" label="IUM" value="ium" hide-details v-show="!verBotones" :rules="[v => v !== null]"></v-checkbox>
                                </div>

                                <!-- Campo para subir el archivo plano -->
                                <div class="subirPlano mt-2" v-show="verInput">
                                    <input class="input" type="file" accept=".csv" ref="archivoInput" @change="cargarArchivo" :disabled="opcionCodigo === null">
                                    <p v-if="cargando" class="text-center mt-5">
                                        Validando la información del archivo, espere un momento...
                                        <v-icon :class="{ 'rotate-animation': cargando }"
                                            large>rotate_right
                                        </v-icon>
                                    </p>
                                    <!-- Mensaje de archivo incorrecto -->
                                    <p v-else-if="formatoIncorrecto" class="d-flex justify-center mt-6 color-red">
                                        <v-icon class="mr-2" color="#ff5252">error</v-icon>
                                        Formato incorrecto, debe subir o arrastrar un archivo .csv.
                                    </p>
                                    <!-- Mensaje general -->
                                    <p v-else class="d-flex text-center mt-3 justify-center">
                                        Arrastre un archivo .CSV con los productos que desea agregar a la norma.
                                        <br>También puede hacer click en este cuadro.
                                    </p>
                                </div>

                                <!-- Tabla que alamacena los datos del archivo csv -->
                                <v-data-table class="elevation csv mt-2" :items="tablaCsv.items" v-show="verTabla"
                                    fixed-header hide-default-footer height="60vh" :items-per-page="tablaCsv.itemsPerPage"> 
                                    <template v-slot:body="{ items }">
                                        <thead>
                                            <tr class="encabezado">
                                                <th>
                                                    <span v-text="opcionCodigo != 'cum' ? 'IUM' : 'CUM'"></span>
                                                </th>
                                                <th>PRECIO MÁXIMO REGULADO</th>
                                                <th v-if="registros.some(registro => registro.productos !== '')">PRODUCTO</th>
                                                <th>VALIDACIÓN</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in items" v-bind:key="item.cum">
                                                <td> {{ item.codigo }} </td>
                                                <td class="text-end"> 
                                                    <span v-if="item.precioMaximo !== ''"> $ {{ formatearPrecio(item.precioMaximo) }}</span>
                                                    <span v-else>{{ item.precioMaximo }}</span>
                                                </td>
                                                <td v-if="registros.some(registro => registro.productos !== '')"> {{ item.productos }}</td>
                                                <td> 
                                                    <span class="color-red" v-if="item.validaciones !== 'ok'">{{ item.validaciones }}</span>
                                                    <span v-else><v-icon color="success">check_circle</v-icon></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-data-table>

                                <!-- Botones -->
                                <div class="d-flex justify-end pt-5">
                                    <v-btn color="error" text @click="clear()"> CERRAR </v-btn>
                                    <v-btn color="blue" text @click="volver()" v-show="verBotones"> VOLVER </v-btn>
                                    <v-btn v-if="!verBotonDescargar" depressed color="success" text v-show="verBotones" @click="guardarNormaProductoCsv()"> GUARDAR </v-btn>
                                    <v-btn v-else depressed color="success" text v-show="verBotones" @click="descargarCsvValidaciones"> DESCARGAR </v-btn>
                                </div>

                            </v-form>
                        </validation-observer>
                    </v-card-text>
                </v-tab-item>

            </v-tabs-items>
        </div>

    </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import Papa from 'papaparse';
import { mapState } from "vuex";
import { Role } from "../router/role.js";
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

extend('required', {
    ...required,
    message: 'Este campo es requerido.',
});

/**
 * Crea y devuelve una versión "debounceada" de la función proporcionada.
 * La función "debounceada" retrasa la invocación de la función original 
 * hasta después de que hayan transcurrido 'delay' milisegundos desde
 * la última vez que la función debounceada fue invocada.
 * 
 * @param {Function} fn - La función original que deseas "debouncear".
 * @param {number} delay - El número de milisegundos a esperar antes de ejecutar 'fn'.
 * @return {Function} Una nueva función que no se ejecuta inmediatamente, sino después del 'delay' especificado.
 */
const debounce = function debounce(fn, delay) {
    let timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        const args = arguments;
        const that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
};

/**
 * Esta función toma una matriz de matrices (una matriz bidimensional) 
 * donde cada sub-matriz (o fila) representa una fila de datos en el CSV.
 * Cada elemento dentro de las sub-matrices se une con un delimitador 
 * (en este caso un punto y coma ';') y cada fila se une con un salto de línea.
 * 
 * @param {Array} data - Matriz bidimensional que contiene los datos.
 * @returns {string} - Cadena representando los datos en formato CSV.
 */
 function arrayToCSV(data) {
    return data.map(row => row.join(";")).join("\n");
}

/**
 * Esta función crea un archivo en formato CSV a partir de una cadena 
 * y luego inicia una descarga en el navegador del usuario para ese archivo.
 * 
 * @param {string} csvData - Cadena que contiene los datos en formato CSV.
 * @param {string} filename - Nombre que se asignará al archivo descargado.
 */
 function downloadCSV(csvData, filename) {
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export default {
    props: [
        'dialogoVerNormaProducto',
        'buscar',
        'formatoFecha',
        'tablaNormaProducto',
        'validarNumeros',
        'normaSeleccionada',
        'listarNormaProducto',
        'crear', 
        'almacenar',
        'clearCrearNormaProducto',
        'listarNorma',
    ],
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            mensajeValidacion: '',
            snackbar: false,
            verBotones: false,
            verInput: true,
            verTabla: false,
            verBotonDescargar: false,
            normaProductoSeleccionado: {},
            editarPrecio: '',
            edicion: false,
            dialogoEliminar: false,
            dialogoTrazabilidad: false,
            tablaTrazabilidad: {
                items: [],
                headers: [
                    { text: "CAMPO", align: "left", width: "15%", sortable: false },
                    { text: "VALOR ANTERIOR", align: "left", width: "15%", sortable: false },
                    { text: "VALOR ACTUAL", align: "left", width: "35%", sortable: false },
                    { text: "FECHA DE MODIFICACIÓN", align: "center", width: "20%", sortable: false },
                    { text: "USUARIO", align: "center", width: "15%", sortable: false },
                ],
                footerProps: {
                    'show-current-page': true,
                    'show-first-last-page': true,
                },
                itemsPerPage: 5,
                page: 1,
            },
            nombresCampos: {
                codigo_producto: 'Código',
                descripcion_producto: 'Producto',
                precio_maximo: 'Precio máximo regulado',
                estado: 'Estado'
            },
            opcionCodigo: 'cum',
            tipoCreacion: 0,
            crearNormaProducto: {
                codigo: '',
                precioMaximo: '',
                descripcion: '',
            },
            errorGeneral: '',
            validarCodigo: '',
            datosEncontrados: {},
            tablaCsv: {
                items: [],
                itemsPerPage: 0
            },
            formatoIncorrecto: false,
            cargando: false,
            validaciones: false,
            botonDisabled: false,
            registros: [],
            userRoles: {},
            roles: Role,
        }
    },
    created() {
        this.userRoles = this.auth.roles;
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),

    },
    watch: {
        'editarPrecio' : function () {
            if (this.editarPrecio) {
                this.validarPrecioEditar();
            }
        },
        'tipoCreacion': function () {
            if (this.tipoCreacion === 0) {
                this.limpiarFormulario();
                this.crearNormaProducto.codigo = '';
                this.verTabla = false;
                this.verBotonDescargar = false;
                this.errorGeneral = '';
            } else {
                this.volver();
                this.formatoIncorrecto = false;
            }
        },
        'opcionCodigo': function () {
            this.cambioDeCodigo();
        },
        'crearNormaProducto.codigo' : debounce(function () {
            if(this.crearNormaProducto.codigo) {
                this.validarProducto();
            }
        },1000),
        'crearNormaProducto.precioMaximo' : debounce(function () {
            if (this.crearNormaProducto.precioMaximo) {
                this.validarPrecio();
            }
        },800),
        'verTabla': function () {
            if (this.verTabla === true) {
                this.calculateItemsPerPage();
            }
        },
        'tablaTrazabilidad.items': function () {
            this.tablaTrazabilidad.page = 1;
        },
    },
    methods: {
        /**
         * Formatea un precio numérico como una cadena en formato de moneda colombiana (COP).
         * Si el precio es nulo, indefinido, una cadena vacía o no es un número, devuelve una cadena vacía.
         * De lo contrario, formatea el precio numérico usando `Intl.NumberFormat` para representarlo como una cantidad de dinero en pesos colombianos (COP).
         * @param {number|string} precio - El precio numérico o una cadena que representa el precio.
         * @returns {string} - El precio formateado como una cadena en formato de moneda colombiana (COP), o una cadena vacía si el precio no es válido.
         */
        formatearPrecio(precio) {
            if (precio === null || precio === undefined || precio === '' || isNaN(precio)) {
                return '';
            } else {
                const precioNumerico = parseFloat(precio);
                const formato = new Intl.NumberFormat('es-CO', { currency: 'COP' });
                return formato.format(precioNumerico);
            }
        },
        /**
         * Método que limpia los campos del forulario si se cambia de código.
         */
        cambioDeCodigo() {
            if (this.$refs.formNormaProducto) {
                this.$refs.formNormaProducto.reset();
            }
            this.limpiarFormulario();
            this.crearNormaProducto.codigo = '';
            this.validarCodigo = '';
            this.errorGeneral = '';
        },
        // Métodos cuando que se usan cuando se agrega el producto a la norma uno a uno
        /**
         *  Valida y procesa la búsqueda del producto según la opción de código seleccionada.
         */
        validarProducto() {
            this.errorGeneral = '';
            this.crearNormaProducto.descripcion = '';
            this.crearNormaProducto.precioMaximo = '';
            if (this.opcionCodigo === 'cum') {
                this.buscarPorCUM();
            } else {
                this.buscarPorIUM();
            }
        },
        /**
         * Restablece los valores de los campos del formulario y sus respectivas validaciones.
         */
        limpiarFormulario() {
            this.crearNormaProducto.descripcion = '';
            this.crearNormaProducto.precioMaximo = '';
            if (this.$refs.formNormaProducto) {
                this.$refs.formNormaProducto.reset();
            }
            this.validarCodigo = '';
        },
        /**
         * Formatea un número ingresado como texto para que represente un precio en formato de moneda colombiana (COP).
         * Limpia el campo especificado del objeto `crearNormaProducto`.
         * Reemplaza cualquier caracter no numérico en el número dado por una cadena vacía.
         * Aplica el formato de moneda colombiana al número usando `Intl.NumberFormat` y lo asigna al campo especificado del objeto `crearNormaProducto`.
         * @param {string} numero - El número ingresado como texto que se formateará como precio.
         * @param {string} campo - El nombre del campo en el objeto `crearNormaProducto` que se actualizará con el precio formateado.
         */
        formatoPrecio(numero, campo) {
            this.crearNormaProducto[campo] = ''
            const precio = numero.replace(/\D/g, '');
            const formato = new Intl.NumberFormat('es-CO', { currency: 'COP'});
            this.crearNormaProducto[campo] = formato.format(precio)
        },
        /**
         * Realiza una búsqueda de productos por Código CUM.
         * Actualiza la descripción del producto y muestra mensajes de validación según la respuesta del servidor.
         */
        buscarPorCUM() {
            this.errorGeneral = '';
            this.crearNormaProducto.descripcion = '';
            if (this.opcionCodigo === 'cum') {
                const codigoCum = this.crearNormaProducto.codigo;
                if (codigoCum) {
                    if (codigoCum !== '1') {
                        this.$http.get(`msa-administration/inMaadi/validarCum`, { params: { codCcum: codigoCum, codEmpr: this.enterprise.code }
                        }).then((response) => {
                            const respuesta = response.data.body;
                            if(respuesta.nomProd) {
                                this.crearNormaProducto.descripcion = respuesta.nomProd; 
                            }

                            if (respuesta.validacion.startsWith('CUM asociado a los productos') || respuesta.validacion.startsWith('El precio máximo') || respuesta.validacion.startsWith('El producto no')) {
                                this.errorGeneral = respuesta.validacion;
                                this.botonDisabled = true;
                            } else {
                                this.validarCodigo = respuesta.validacion;
                                this.botonDisabled = true;
                            }

                            if (respuesta.validacion === 'ok') {
                                this.validarCodigo = '';
                            }

                            this.datosEncontrados.idProducto = respuesta.idProducto;
                            this.datosEncontrados.codProd = respuesta.codProd;
                            this.datosEncontrados.rmtCupc = respuesta.rmtCupc;
                        }).catch((error) => {
                            console.log(error);
                        })
                    } else{
                        this.errorGeneral = 'Código CUM inválido.';
                        this.limpiarFormulario();
                    }
                } else {
                    this.limpiarFormulario();
                }
            }
        },
        /**
         * Realiza una búsqueda de productos por Código IUM.
         * Verifica si el código de entrada es un valor válido de IUM.
         * Actualiza la descripción del producto y el estado de validación según la respuesta del servidor.
         */
        buscarPorIUM() {
            this.errorGeneral = '';
            this.crearNormaProducto.descripcion = '';
            if (this.opcionCodigo === 'ium') {
                const codigoIum = this.crearNormaProducto.codigo;
                const noIum = ['0', '1' , 'ii', 'i', 'lv', 'lll'];
                const codigoMinuscula = codigoIum.toLowerCase();
                if (!noIum.includes(codigoMinuscula)) {
                    this.$http.get(`msa-administration/inCapit/validarIum`, { params: { rmtCapi: codigoIum, codEmpr: this.enterprise.code }
                    }).then((response) => {
                        const respuesta = response.data.body;
                        if(respuesta.nomProd) {
                            this.crearNormaProducto.descripcion = respuesta.nomProd; 
                        }

                        if (respuesta.validacion.startsWith('CUM asociado a los productos')) {
                            this.errorGeneral = respuesta.validacion;
                            this.botonDisabled = true;
                        } else {
                            this.validarCodigo = respuesta.validacion;
                            this.botonDisabled = true;
                        }

                        if (respuesta.validacion === 'ok') {
                            this.validarCodigo = '';
                        }

                        this.datosEncontrados.idProducto = respuesta.idProducto;
                        this.datosEncontrados.codProd = respuesta.codProd;
                        this.datosEncontrados.rmtCupc = respuesta.rmtCupc;
                    }).catch((error) => {
                        console.log(error);
                    })
                } else {
                    this.errorGeneral = 'Código IUM inválido.'
                    this.limpiarFormulario();
                }
            }
        },
        /**
         * Elimina el formato de número antes de mandar la petición al back
         */
        quitarFormato(precio) {
            let precioSinPuntos;
            const tieneAlfabeticos = /\./.test(precio);
            if (tieneAlfabeticos) {
                precioSinPuntos = precio.replace(/\D/g, '');
            } else {
                precioSinPuntos = precio; 
            }
            return precioSinPuntos;
        },
        /**
         * Valida el precio máximo de un producto para verificar que no sea menor al precio rentable
         * de la lista de precios.
         */
        validarPrecio() {
            this.errorGeneral = '';
            const precioMaximo = this.crearNormaProducto.precioMaximo.replace(/\D/g, '');
            if (this.datosEncontrados.codProd) {
                this.$http.get(`msa-administration/faPreci/buscarPrecios`, {
                    params: {
                        precio: precioMaximo,
                        codProd: this.datosEncontrados.codProd
                    }
                }).then((response) => {
                    const respuesta = response.data.body;
                    if (respuesta === 'ok') {
                        this.botonDisabled = false;
                    } else {
                        this.errorGeneral = respuesta;
                        this.botonDisabled = true;
                    }
                }).catch((error) => {
                    console.log(error);
                })
            }
        },
        /**
         * Guarda el producto en la norma seleccionada.
         * En función de la opción de código (CUM o IUM).
         */
        guardarNormaProducto() {
            let detalleNorma;
            if (this.opcionCodigo === 'cum') {
                detalleNorma = {
                    norma: {
                        idNorma: this.normaSeleccionada.idNorma
                    },
                    producto: {
                        idProducto: this.datosEncontrados.idProducto,
                        codigoProducto: this.datosEncontrados.codProd,
                    },
                    codigoUnicoProducto: {
                        idCupc: this.datosEncontrados.rmtCupc
                    },
                    precioMaximo: this.crearNormaProducto.precioMaximo.replace(/\D/g, ''),
                    codigoCUM: this.crearNormaProducto.codigo,
                    estado: false,
                    lastmodifiedby: this.lastmodifiedby
                };
            } else {
                detalleNorma = {
                    norma: {
                        idNorma: this.normaSeleccionada.idNorma
                    },
                    producto: {
                        idProducto: this.datosEncontrados.idProducto,
                        codigoProducto: this.datosEncontrados.codProd,
                    },
                    codigoUnicoProducto: {
                        idCupc: this.datosEncontrados.rmtCupc
                    },
                    precioMaximo: this.crearNormaProducto.precioMaximo.replace(/\D/g, ''),
                    codigoIUM: this.crearNormaProducto.codigo,
                    estado: false,
                    lastmodifiedby: this.lastmodifiedby
                };
            }
            this.$http.post(`msa-administration/detalleNorma/guardar`, [detalleNorma], {params:{idEmpresa: this.enterprise.code}} )
            .then(() => {
                this.clearCrearNormaProducto();
                this.limpiarFormulario();
                this.crearNormaProducto = {};
            }).catch((error) => {
                console.log(error);
            })
        },
        // Métodos cuando que se hace una carga masiva de productos a la norma       
        /**
         * Método para cargar un archivo CSV y procesar su contenido.
         * @param {*} event - El evento del cambio de archivo.
         */
         cargarArchivo(event) {
            this.registros = [];
            this.formatoIncorrecto = false;
            const file = event.target.files[0];
            // Verificar si se seleccionó un archivo y si su extensión es CSV.
            if (!file || file.name.split('.').pop() !== 'csv') {
                this.formatoIncorrecto = true;
            } else {
                // Leer el contenido del archivo.
                const reader = new FileReader();
                reader.onload = (e) => {
                    // Parsear el contenido CSV.
                    const content = e.target.result;
                    this.procesarArchivo(content);
                };
                reader.readAsText(file);
                this.formatoIncorrecto = false;
            }
        },
        /**
         * Procesa el contenido de un archivo CSV.
         * @param {*} content El contenido del archivo CSV.
         */
        async procesarArchivo(content) {
            const parsedData = Papa.parse(content, {
                header: false,
                delimiter: ';',
            });
            
            // // Verificar si la primera fila es un encabezado válido.
            const encabezado = parsedData.data[0];
            const esEncabezado = (/^[a-zA-Z]*$/.test(encabezado[0]) || /^[a-zA-Z\s]*$/.test(encabezado[0])) &&
            (/^[a-zA-Z]*$/.test(encabezado[1]) || /^[a-zA-Z\s]*$/.test(encabezado[1]));
            
            // Iterar sobre las filas de datos.
            for (let index = esEncabezado ? 1 : 0; index < parsedData.data.length - 1; index++) {
                const row = parsedData.data[index];
                const codigoCsv = typeof row[0] === 'string' ? row[0] : '';
                const precio = /^\d+$/.test(row[1]) ? parseInt(row[1]) : '';
                
                // Agregar registros al arreglo si el código y el precio son válidos.
                if (row[0] !== '' || row[1] !== '') {
                    this.registros.push({
                        codigo: codigoCsv,
                        precioMaximo : precio,
                    });
                }
            }
            
            this.cargando = true;
            let backendCalls = [];

            const data = parsedData.data.map(row => ({ codigo: row[0], precio: row[1] }));
            if (this.opcionCodigo != 'cum') {
                backendCalls = await this.buscarPorIUMCsv(data);
            } 
            else {
                backendCalls = await this.buscarPorCUMCsv(data);
            }
            
            try {
                await Promise.all(backendCalls);

                const registrosDuplicados = this.registros.filter((registro, index, self) =>
                    index !== self.findIndex((r) => r.codigo.trim() === registro.codigo.trim())
                );
                // Marcar registros duplicados con la validación correspondiente.
                if (registrosDuplicados.length !== 0) {
                    registrosDuplicados.forEach((registro) => {
                        registro.validaciones = 'Registro duplicado.';
                    });
                } 

                // Validación si el precio está vacío.
                const precioVacio = this.registros.filter(registro => registro.precioMaximo === '')
                if (precioVacio.length !== 0) {
                    precioVacio.forEach((registro) => {
                        registro.validaciones = 'El precio máximo no puede estar vacío.'
                    })
                }

                // Validación si el código está vacío.
                const codigoVacio = this.registros.filter(registro => registro.codigo === '')
                if (codigoVacio.length !== 0) {
                    codigoVacio.forEach((registro) => {
                        registro.validaciones = `El código ${this.opcionCodigo.toUpperCase()} no puede estar vacío.`
                    })
                }

                // Si al menos un registro no pasa las validaciones
                const registrosConErrores = this.registros.filter(registro => registro.validaciones !== 'ok');

                if (registrosConErrores.length >= 1) {
                    // Mostrar solo los registros con validaciones diferentes de 'ok'
                    this.registros = registrosConErrores;
                    this.verBotonDescargar = true;
                }

                this.tablaCsv.items = this.registros;
                this.verTabla = true;
                this.verInput = false;
                this.verBotones = true;
                this.cargando = false;
            } catch(error) {
                console.error(error);
                this.cargando = false;
                this.verBotones = false;
            }
        },
        /**
         * Realiza una búsqueda de productos por Código CUM.
         * Filtra los códigos inválidos antes de realizar la solicitud.
         * Actualiza los registros con los resultados de la búsqueda.
         * 
         * @param {Array<string>} codigos - Lista de códigos CUM a buscar.
         * @returns {Array<Object>} - Una lista actualizada de registros con los resultados de la búsqueda.
         */
        async buscarPorCUMCsv(data) {
            try {
                const registrosValidos = data.filter(registro => {
                    const codigoValido = registro.codigo !== '1' && registro.codigo.trim() !== '';
                    const precioValido = !isNaN(parseFloat(registro.precio)) && registro.precio.trim() !== '';
                    return codigoValido && precioValido;
                });
                const response = await this.$http.post(`msa-administration/inMaadi/validarCumCsv`, registrosValidos, {
                    params:{
                        codEmpr: this.enterprise.code,
                    }
                });
                const responseData = response.data;
                const datos = responseData.map(item => item.body);
                this.registros.forEach((registro) => {
                    const codigo = registro.codigo;
                    if (codigo === '1') {
                        registro.validaciones = 'Código CUM inválido.';
                    }
                    const bodyData = datos.find(item => item.codigo === codigo);
                    if (bodyData) {
                        registro.validaciones = bodyData.validacion;
                        registro.productos = bodyData.nomProd ? bodyData.nomProd : '';
                        registro.codProd = bodyData.codProd;
                        registro.rmtCupc = bodyData.rmtCupc;
                        registro.idProducto = bodyData.idProducto;
                    }
                });
                return this.registros;
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * Realiza una búsqueda de productos por Código IUM.
         * Filtra los códigos de entrada para excluir valores no válidos de IUM.
         * Actualiza los registros con mensajes de validación y datos de productos obtenidos del servidor.
         * @param {Array} codigos - Lista de códigos de productos para buscar.
         * @returns {Array} - Registros actualizados con mensajes de validación y datos de productos.
         */
        async buscarPorIUMCsv(data) {
            try {
                const noIum = ['0', '1' , 'ii', 'i', 'lv', 'lll'];
                const registrosValidos = data.filter(registro => {
                    const codigoValido = registro.codigo.trim() !== '' && !noIum.includes(registro.codigo.toLowerCase());

                    const precioValido = !isNaN(parseFloat(registro.precio)) && registro.precio.trim() !== '';
                    
                    return codigoValido && precioValido;
                });
                const response = await this.$http.post(`msa-administration/inCapit/validarIumCsv`,registrosValidos, { 
                    params: {
                        codEmpr: this.enterprise.code 
                    }
                });
                const responseData = response.data;
                const datos = responseData.map(item => item.body);
                this.registros.forEach((registro) => {
                    const codigo = registro.codigo;
                    const bodyData = datos.find(item => item.codigo === codigo);
                    
                    if (noIum.some(ium => codigo.toLowerCase().includes(ium))) {
                        registro.validaciones = 'Código IUM inválido.';
                    }

                    if (bodyData) {
                        registro.validaciones = bodyData.validacion;
                        registro.productos = bodyData.nomProd ? bodyData.nomProd : ''; 
                        registro.codProd = bodyData.codProd;
                        registro.rmtCupc = bodyData.rmtCupc;
                        registro.idProducto = bodyData.idProducto;
                    }
                });
                return this.registros;
            } catch(error) {
                console.log(error);
            }
        },
        /**
         * Calcula el número total de registros cargados del archivo CSV
         */
         calculateItemsPerPage() {
            const totalRecords = this.tablaCsv.items.length;
            this.tablaCsv.itemsPerPage = totalRecords;
        },
        /**
         * Guarda los productos masivamente en la norma seleccionada.
         * En función de la opción de código (CUM o IUM).
         */
        guardarNormaProductoCsv() {
            let detalleNorma;
            if (this.opcionCodigo === 'cum') {
                detalleNorma = this.registros.map((dato) => ({
                    norma: {
                        idNorma: this.normaSeleccionada.idNorma
                    },
                    producto: {
                        idProducto: dato.idProducto,
                        codigoProducto: dato.codProd,
                    },
                    codigoUnicoProducto: {
                        idCupc: dato.rmtCupc
                    },
                    precioMaximo: dato.precioMaximo,
                    estado: false,
                    lastmodifiedby: this.lastmodifiedby,
                    codigoCUM: dato.codigo
                }));
            } else {
                detalleNorma = this.registros.map((dato) => ({
                    norma: {
                        idNorma: this.normaSeleccionada.idNorma
                    },
                    producto: {
                        idProducto: dato.idProducto,
                        codigoProducto: dato.codProd,
                    },
                    codigoUnicoProducto: {
                        idCupc: dato.rmtCupc
                    },
                    precioMaximo: dato.precioMaximo,
                    estado: false,
                    lastmodifiedby: this.lastmodifiedby,
                    codigoIUM: dato.codigo
                }));
            }
            this.$http.post(`msa-administration/detalleNorma/guardar`, detalleNorma, {params:{idEmpresa: this.enterprise.code}} )
                .then(() => {
                    this.clearCrearNormaProducto();
                    this.listarNorma();
                    this.volver();
                }).catch((error) => {
                    console.log(error);
                })
        },
        /** 
         * Este método permite descargar en formato CSV los registros que no pasaron las validaciones.
         * Realiza una solicitud HTTP POST para exportar los datos en formato CSV.
         */
        descargarCsvValidaciones() {
            this.$http
            .post(`msa-administration/detalleNorma/exportarValidacion`,this.tablaCsv.items,{
                responseType: 'blob',
                params: {
                    tipoCodigo: this.opcionCodigo
                }
            }).then(response => {
                const filename = `VALIDACIONES DE LA ${this.normaSeleccionada.descripcionNorma}.csv`;
                Papa.parse(response.data, {
                    complete: (results) => {
                        const csvData = arrayToCSV(results.data);
                        downloadCSV(csvData, filename);
                    }
                });
            }).catch(error => {
                console.log(error);
            })
        },
        /**
         * Restaura las variables cuando cargado el CSV en la tabla se le da clic al botón "volver", para proceder a subir el archivo csv de nuevo.
         */
        volver() {
            this.verBotonDescargar = false,
            this.verTabla = false;
            this.verInput = true;
            this.tablaCsv.items = [];
            this.verBotones = false;
            this.formatoIncorrecto = false;
            if (this.$refs.archivoInput) {
                this.$refs.archivoInput.value = '';
            }
            this.cargando = false;
        },
        // Métodos que se usan en la tabla de los productos
        /**
         * Permite la edición del precio máximo.
         * @param {*} item Elemento que se va a editar.
         */
        verCampoEditar(item) {
            this.$set(item, 'editar', true);
            this.edicion = true;
            this.editarPrecio = this.formatearPrecio(item.precioMaximo);
            this.datosEncontrados.codProd = item.codigoProducto;
            this.tablaNormaProducto.footerProps = {
                'disablePagination' : true, 
                'disableItemsPerPage' : true ,
                'items-per-page-options': [10, 15, 20, 30],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            }
        },
        /**
         * Cancela la edición del precio máximo.
         * @param {*} item Elemento al que se le quiere cancelar la edición.
         */
        ocultarCampoEditar(item) {
            if (item && typeof item === 'object' && 'editar' in item) {
                this.$set(item, 'editar', false);
            }
            this.edicion = false;
            this.editarPrecio = '';
            this.datosEncontrados.codProd = '';
            this.snackbar = false;
            this.tablaNormaProducto.footerProps = {
                'disablePagination' : false, 
                'disableItemsPerPage' : false ,
                'items-per-page-options': [10, 15, 20, 30],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            }
        },
        /**
         * Le agrega un formato de moneda al campo que edita el precio.
         */
        formatearPrecioCampoEdicion() {
            // Eliminar caracteres no numéricos y formatear el precio
            const precioNumerico = this.editarPrecio.replace(/\D/g, '');
            const formato = new Intl.NumberFormat('es-CO', { currency: 'COP' });

            // Actualizar editarPrecio con el valor formateado
            this.editarPrecio = formato.format(precioNumerico);
        },
        /**
         * Actualiza el precio máximo de un producto en la norma y actualiza la lista.
         * @param {Object} item - El elemento que se va a actualizar.
         */
        actualizarPrecio(item) {
            this.normaProductoSeleccionado = item;
            const precio = {
                idDNorma: this.normaProductoSeleccionado.idDNorma,
                precioMaximo: this.editarPrecio.replace(/\D/g, ''),
                eliminado: false,
                estado: false
            }
            this.$http
                .put(`msa-administration/detalleNorma/actualizar`, precio)
                .then(() => {
                    this.ocultarCampoEditar();
                    this.listarNormaProducto();
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Abre el diálogo de confirmación de eliminación para el elemento especificado.
         * @param {*} item El elemento que se va a eliminar.
         */
        abrirDialogoEliminar(item) {
            this.dialogoEliminar = true;
            this.normaProductoSeleccionado = item;
        },
        /**
         * Valida el precio máximo de un producto para verificar que no sea menor al precio rentable
         * de la lista de precios al editar el precio máximo en la tabla de los productos de la norma.
         */
        validarPrecioEditar() {
            const precioMaximo = this.quitarFormato(this.editarPrecio);
            if (this.datosEncontrados.codProd) {
                this.$http.get(`msa-administration/faPreci/buscarPrecios`, {
                    params: {
                        precio: precioMaximo,
                        codProd: this.datosEncontrados.codProd
                    }
                }).then((response) => {
                    const respuesta = response.data.body;
                    if (respuesta !== 'ok') {
                        this.mensajeValidacion = respuesta;
                        this.snackbar = true;
                    } else {
                        this.mensajeValidacion = '';
                        this.snackbar = false;  
                    }
                }).catch((error) => {
                    console.log(error);
                })
            }
        },
        /**
         * Elimina el producto de la norma y actualiza la lista.
         */
        eliminarProductoNorma() {
            const eliminar = {
                idDNorma: this.normaProductoSeleccionado.idDNorma,
                precioMaximo: this.normaProductoSeleccionado.precioMaximo,
                eliminado: true
            }
            this.$http
                .put(`msa-administration/detalleNorma/actualizar`, eliminar)
                .then(() => {
                    this.dialogoEliminar = false;
                    this.listarNormaProducto();
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Abre el diálogo de trazabilidad y carga los datos de trazabilidad para el producto seleccionado.
         * @param {Object} item - El elemento seleccionado para ver su trazabilidad.
         */
        verTrazabilidad(item) {
            this.dialogoTrazabilidad = true;
            this.normaProductoSeleccionado = item;
            this.$http.get(`msa-administration/detalleNorma/trazabilidad`, {
                params: {
                    id: this.normaProductoSeleccionado.idDNorma
                }
            }).then((response) => {
                this.tablaTrazabilidad.items = response.data;
            }).catch((error) => {
                console.log(error);
            })
        },
        /**
         * Valida si el campo de un objeto debe ser excluido en la tabla de trazabilidad.
         * @param {Object} item - El objeto que contiene el campo a validar.
         * @param {string} item.campo - El nombre del campo a validar.
         */
        validarCampos(item) {
            const excluir = ['codigo_producto', 'descripcion_producto', 'precio_maximo']
            return excluir.includes(item.campo)
        },
        /**
         * Método que restablece varias variables y propiedades a sus valores predeterminados o vacíos, también restablece los campos de entrada de un 
         * formulario a su estado inicial.
         * Se utiliza para limpiar y preparar el estado del programa antes de realizar una nueva operación.
         */
        clear() {
            setTimeout(() => {
                this.tipoCreacion = 0;
                this.opcionCodigo = 'cum';
                if (this.$refs.formNormaProducto) {
                    this.$refs.formNormaProducto.reset();
                }
                this.validarCodigo = '';
                this.errorGeneral = '';
                this.verTabla = false;
                this.verInput = true;
                this.verBotonDescargar = false;
                this.tablaCsv.items = [];
                if (this.$refs.archivoInput) {
                    this.$refs.archivoInput.value = '';
                }
                this.cargando = false;
            }, 500);
            this.clearCrearNormaProducto();
            this.crearNormaProducto = {};
        },
    },
    mounted() {
        this.lastmodifiedby = this.auth.username.toUpperCase();
    }
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.encabezado {
    background-color: #1867c0;
    color: white;
}
::v-deep .elevation .v-data-footer {
    width: 100%;
}
::v-deep .elevation .v-data-footer .v-data-footer__select{
    height: 2rem;
}
::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
::v-deep .csv td {
    max-width: 28rem !important;
}
::v-deep .csv th {
    color: black !important;
}
::v-deep .trazabilidad .v-data-footer__select {
    display: none;
}
::v-deep .trazabilidad .v-data-footer {
    justify-content: end;
}
::v-deep .v-tabs-slider-wrapper {
    top: 88% !important;
    margin-left: 16px !important;
}
.slider {
    width: 326px !important;
}
.subirPlano {
    outline: 2px dashed grey;
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 1rem;
    height: 100px;
    position: relative;
    cursor: pointer;
}
.input {
    opacity: 0;
    width: 100%;
    height: 100px;
    position: absolute;
    cursor: pointer;
}
.rotate-animation {
    animation: rotate 2s linear infinite;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.checkbox {
    width: 8rem;
}
.norma {
    font-size: 14.5px;
    width: 93%;
}
.fila {
    width: 50%;
}
.color-red {
    color: #ff5252;
}
.encabezado th {
    min-width: 8rem;
    max-width: 22rem;
}
</style>